.highlight-container {
    position: relative;

    @media(min-width: #{$brk-med}) {
        display: grid;
        grid-template-columns: 1fr 90% 1fr;
    }
    
}

.highlight-meta {
    color: white;
    white-space: pre-wrap;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    @media(min-width: #{$brk-med}) {
        grid-column: span 3;
    }
}

.highlight-hero {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    max-height: 250px;
    overflow: hidden;

    @media(min-width: #{$brk-med}) {
        max-height: 330px;
        grid-column-start: 1;
        grid-column-end: 4;
    }
}

.highlight-hero img {
    width: 100%;
    height: max-content;

}


.heroText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 3;
}

.imgOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: .5;
    z-index: 2;    
}

//Image Gallery

.showcase {
    width: 100%;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    gap: .7em;
    padding: 8px;
    
    @media(min-width: #{$brk-sml}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media(min-width: #{$brk-med}) {
        max-width: 1500px;
        grid-column-start: 2;
        justify-self: center;
    }

    @media(#{$mq-xxlarge}) {
        max-width: 3500px;
        grid-template-columns: repeat(5, 1fr);
    }
}

.return-link-container {
    display: flex;
    justify-content: center;
    padding: 8px;
}

.return-home-link {
    color: white;
    text-align: center;
}

.showcase-image {
    width: 100%;
    border-radius: 15px;
    z-index: 1;
}