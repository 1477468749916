//Project Variables
$theme-color-pink: #FFAFFF;
$theme-default-font: "Quicksand", sans-serif;
$theme-font-weight-heading: 300;

$txt-white-1: #FFFFFF;
$text-white-2: #e3e3e3;

//Breakpoints
$brk-sml: 48em; // 768px
$brk-med: 64em; //1024px
$brk-lrg: 85.375em; //1366px
$brk-xl: 120em; // 1920px
$brk-xxl: 160em; //2560px

//Media queries
$mq-small: "(min-width: #{$brk-sml})";
$mq-medium: "(min-width: #{$brk-med})";
$mq-large: "(min-width: #{$brk-lrg})";
$mq-xlarge: "(min-width: #{$brk-xl})";
$mq-xxlarge: "(min-width: #{$brk-xxl})";

//Transitions
$trans-ease-fast: 0.3s ease;
$trans-ease-slow: 0.6s ease;

//Font Size and Line Height
$base-font-size: 1em;
$base-line-height: 1.5;

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
}

//Scale Margins
.container {
    margin: 0;
  
    @media #{$mq-medium} {
      margin: 0 6%;
    }
  
    @media #{$mq-large} {
      margin: 0 10%;
    }
  
    @media #{$mq-xlarge} {
      margin: 0 14%;
    }
  }

//Grid Utilities
.grid-col-span-1 {
  grid-column: span 1;
}

.grid-col-span-2 {
  grid-column: span 2;
}

.grid-col-span-3 {
  grid-column: span 3;
}

.grid-row-span-1 {
  grid-row: span 1;
}

.grid-row-span-2 {
  grid-row: span 2;
}

.grid-row-span-3 {
  grid-row: span 3;
}

//Global Styles

body {
    background-color: #0D0D0D;
    font-size: $base-font-size;
    //Scale Font Size
    @media #{$mq-medium} {
        font-size: $base-font-size*1.2;
        line-height: $base-line-height*1.2;
      }
    
      @media #{$mq-large} {
        font-size: $base-font-size*1.3;
      }
    
      @media #{$mq-xlarge} {
        font-size: $base-font-size*1.4;
      }
    
      @media #{$mq-xxlarge} {
        font-size: $base-font-size*1.6;
      }
}

h1, h2 {
    font-weight: #{$theme-font-weight-heading};
    font-family: #{$theme-default-font};
}

h1 {
    font-size: 2.5em;
}

h2 {
    font-size: 2em;
    @media#{$mq-medium}{font-size: 2.2em; line-height: 1;}
    @media#{$mq-large}{font-size: 2.7em; line-height: 1;}
}

//CSS imports
@import 'header';
@import 'projects';
@import 'highlight';
@import 'lightbox';