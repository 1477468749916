//Projects renderer:

.projects_container {
    display: grid;
    row-gap: 1em;
    grid-template-columns: 1fr;

    @media #{$mq-medium} {
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
    }
}

//Project Cards:
.project_card_container {
    width: 100%;
    aspect-ratio: 16 / 8;
    border-radius: 20px;
    overflow: hidden;

    :hover .project-card-overlay {
        opacity: 0.3;
    }

    :hover .project-card-text {
        opacity: 1;
    }

    :hover .hero-image {
        transform: scale(1.2);
    }
}

.hero-image-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.hero-image {
    width: 100%;
    transition: #{$trans-ease-slow};
    z-index: 1;
}

.project-card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .3;
    transition: #{$trans-ease-fast};
    z-index: 2;

    @media #{$mq-medium} {
        opacity: 0;
    }
}

.project-card-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #{$text-white-2};
    text-align: center;
    transition: #{$trans-ease-fast};
    z-index: 3;

    @media #{$mq-medium} {
        opacity: 0;
    }
}