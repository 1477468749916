h1 {
    color: green;
}

.header-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.nav-container {
    background-color: red;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
}

.nav-items {
    display: flex;
}

#header-first-name {
    color: white;
    font-weight: 300;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #{$theme-color-pink};
}

#header-last-name {
    color: #{$theme-color-pink};
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: #{$theme-color-pink};
}

.show-hide-btn {
    background-color: green;
    border-style: none;
    height: 2.5em;
}

.burger {
    width: 100%;
    height: 100%;
}